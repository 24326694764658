import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RxDashboard } from "react-icons/rx";
import { BsPerson } from "react-icons/bs";
import { BiShoppingBag } from "react-icons/bi";
import { GrCertificate, GrObjectGroup } from "react-icons/gr";
import { RiLogoutCircleLine, RiAdminFill, RiSettings2Fill } from "react-icons/ri";
import { PiPackage } from "react-icons/pi";
import { FaUserTie } from "react-icons/fa";
import NavLink from "./NavLink";
import dp from "../../assets/user.png";
import { useAdminContext } from "../../context/adminContext";

const Sidebar = ({ onClose, isSidebarOpen, isMobile }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isStaffOpen, setIsStaffOpen] = useState(true)
  const { setAdminToken, adminData } = useAdminContext();

  useEffect(() => {
    navigate("/admin/dashboard");
  }, []);

  const adminLogoutHandler = () => {
    sessionStorage.removeItem("adminToken");
    setAdminToken("");
    navigate("/admin");
  };

  const handleClose = (e) => {
    if (!isMobile) return;
    if (e.target.classList.contains("sidebar-content")) return;
    onClose();
  };

  return (
    <div
      onClick={handleClose}
      className={`${
        isSidebarOpen
          ? "translate-x-0 w-full bg-black bg-opacity-20"
          : "-translate-x-full w-auto"
      } transform transition-transform duration-300 md:static fixed md:translate-x-0 top-0 left-0 bottom-0 z-10`}
    >
      <div className="bg-[#394e86] text-white h-screen w-16 md:w-48 lg:w-64 shadow-xl flex flex-col items-center pt-14 md:pt-6 sidebar-content">
        {/* Profile Section */}
        <div className="flex flex-col items-center gap-3 w-full mb-6">
          <div className="border-2 border-white rounded-full w-16 h-16 overflow-hidden">
            <img
              className="w-full h-full object-cover"
              src={dp}
              alt="Admin Profile"
            />
          </div>
          <h2 className="hidden md:block text-lg font-semibold text-center">
            {adminData?.name || "Super Admin"}
          </h2>
        </div>

        {/* Navigation Links */}
        <nav className="flex flex-col  w-full px-2 md:px-4 gap-4">
          <NavLink
            to="/admin/dashboard"
            icon={<RxDashboard size={25} />}
            label="Dashboard"
            location={location}
          />
          <NavLink
            to="/admin/candidates"
            icon={<BsPerson size={25} />}
            label="Candidates"
            location={location}
          />
          <NavLink
            to="/admin/employers"
            icon={<BiShoppingBag size={25} />}
            label="Employers"
            location={location}
          />
          
          <NavLink
            to="/admin/jobs"
            icon={<GrCertificate size={25} />}
            label="Jobs"
            location={location}
          />
          {adminData?.isSuperAdmin && (
              <NavLink
                to="/admin/packages"
                icon={<PiPackage size={25} />}
                label="Packages"
                location={location}
              />
          )}
          <div onClick={()=>setIsStaffOpen(prev => !prev)} className="w-full p-2 px-4 hover:text-[#6ad61d] cursor-pointer hover:bg-white transition duration-300 flex flex-col  md:flex-row items-center justify-center sm:justify-normal md:gap-1"> 
            <GrObjectGroup size={25}/> 
            <p>Staff Management</p>
          </div>

          {isStaffOpen &&  
                <div className="flex flex-col items-center ">
                    <div className="">
                    <NavLink
                      to="/admin/HiringRecuiter"
                      icon={<FaUserTie size={25} />}
                      label="HR"
                      location={location} />

                    <NavLink
                      to="/admin/admins"         //change 
                      icon={<FaUserTie size={25} />}
                      label="Admin"
                      location={location} />

                    <NavLink
                      to="/admin/staff"
                      icon={<FaUserTie size={25} />}
                      label="Staff"
                      location={location} />


                    </div>
                </div>
          }


            <NavLink
            to="/admin/jobs"
            icon={<RiSettings2Fill size={25} />}
            label="Settings"
            location={location}
          />

          {/* Logout Button */}
          <button
            onClick={adminLogoutHandler}
            className="flex items-center gap-4 text-white hover:bg-[#2563EB] transition-colors duration-300 rounded-lg p-2 w-full"
          >
            <RiLogoutCircleLine size={25} />
            <span className="hidden md:block">Logout</span>
          </button>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
