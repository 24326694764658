import React from "react";

const Certification = ({ certifications, setCertifications }) => {
  const handleAddCertification = () => {
    setCertifications((prev) => [
      ...prev,
      { title: "", organization: "", year: "", issueDate: "", description: "" },
    ]);
  };

  const handleRemoveCertification = (index) => {
    setCertifications((prev) =>
      prev.filter((_, certIndex) => certIndex !== index)
    );
  };

  const handleCertificationChange = (index, field, value) => {
    setCertifications((prev) =>
      prev.map((cert, certIndex) =>
        certIndex === index ? { ...cert, [field]: value } : cert
      )
    );
  };

  return (
    <div className="bg-white p-6 mt-5 px-10 rounded-lg shadow-md">
      <h2 className="text-lg text-[#202124] mb-6 font-bold">Certifications</h2>
      {certifications.map((cert, index) => (
        <div
          key={index}
          className="flex flex-wrap items-center gap-4 border-b pb-4 mb-4"
        >
          {/* Certification Title */}
          <div className="flex-1">
            <label className="block text-sm font-medium mb-1 text-gray-700">
              Certification Title
            </label>
            <input
              type="text"
              placeholder="E.g., Certified Scrum Master"
              value={cert.title}
              onChange={(e) =>
                handleCertificationChange(index, "title", e.target.value)
              }
              className="block w-full p-3 bg-gray-100 border-gray-300 rounded-lg"
            />
          </div>

          {/* Issuing Organization */}
          <div className="flex-1">
            <label className="block text-sm font-medium mb-1 text-gray-700">
              Issuing Organization
            </label>
            <input
              type="text"
              placeholder="E.g., Scrum Alliance"
              value={cert.organization}
              onChange={(e) =>
                handleCertificationChange(index, "organization", e.target.value)
              }
              className="block w-full p-3 bg-gray-100 border-gray-300 rounded-lg"
            />
          </div>

          {/* Year */}
          <div className="flex-1">
            <label className="block text-sm font-medium mb-1 text-gray-700">
              Year
            </label>
            <input
              type="text"
              placeholder="E.g., 2023"
              value={cert.year}
              onChange={(e) =>
                handleCertificationChange(index, "year", e.target.value)
              }
              className="block w-full p-3 bg-gray-100 border-gray-300 rounded-lg"
            />
          </div>

          {/* Issue Date */}
          <div className="flex-1">
            <label className="block text-sm font-medium mb-1 text-gray-700">
              Issue Date
            </label>
            <input
              type="date"
              value={cert.issueDate}
              onChange={(e) =>
                handleCertificationChange(index, "issueDate", e.target.value)
              }
              className="block w-full p-3 bg-gray-100 border-gray-300 rounded-lg"
            />
          </div>

          {/* Description */}
          <div className="flex-1">
            <label className="block text-sm font-medium mb-1 text-gray-700">
              Description
            </label>
            <textarea
              placeholder="E.g., Completed a professional course on..."
              value={cert.description}
              onChange={(e) =>
                handleCertificationChange(index, "description", e.target.value)
              }
              className="block w-full p-3 bg-gray-100 border-gray-300 rounded-lg resize-none"
            />
          </div>

          {/* Remove Button */}
          <div className="mt-2">
            <button
              type="button"
              onClick={() => handleRemoveCertification(index)}
              className="text-red-500 font-medium hover:underline"
            >
              Remove
            </button>
          </div>
        </div>
      ))}

      <button
        type="button"
        onClick={handleAddCertification}
        className="py-2 px-4 bg-[#6ad61d] text-white font-semibold rounded-lg hover:bg-[#5bb017] transition duration-200"
      >
        Add Certification
      </button>
    </div>
  );
};

export default Certification;