export const designations = [
  { label: "Owner", value: "owner" },
  { label: "HR Manager", value: "hr_manager" },
  { label: "Recruitment Specialist", value: "recruitment_specialist" },
  {
    label: "Talent Acquisition Specialist",
    value: "talent_acquisition_specialist",
  },
  { label: "Hiring Manager", value: "hiring_manager" },
  { label: "Recruiter", value: "recruiter" },
  { label: "HR Director", value: "hr_director" },
  { label: "HR Business Partner", value: "hr_business_partner" },
  { label: "Head of Recruitment", value: "head_of_recruitment" },
  { label: "HR Coordinator", value: "hr_coordinator" },
  { label: "HR Generalist", value: "hr_generalist" },
  { label: "HR Consultant", value: "hr_consultant" },
  { label: "HR Associate", value: "hr_associate" },
  { label: "HR Executive", value: "hr_executive" },
  { label: "HR Administrator", value: "hr_administrator" },
  { label: "Staffing Manager", value: "staffing_manager" },
  { label: "Talent Scout", value: "talent_scout" },
  { label: "Employee Relations Manager", value: "employee_relations_manager" },
  { label: "HR Officer", value: "hr_officer" },
];
