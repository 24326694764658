import { Link } from "react-router-dom";

const NavLink = ({ to, icon, label, location }) => {
  const isActive = location.pathname === to;

  return (
    <Link
      to={to}
      className={`w-full p-2 px-4 hover:bg-white hover:text-[#6ad61d]  transition duration-300 flex flex-col  md:flex-row items-center justify-center sm:justify-normal md:gap-1 ${
        isActive ? "bg-white rounded-sm text-[#6ad61d]" : ""
      }`}
    >
      {icon}
      <p
        className={` text-[8px] md:text-sm text-start ${
          isActive ? " text-[#6ad61d]"  : ""
        }`}
      >
        {label}
      </p>
    </Link>
  );
};

export default NavLink;
