import React from 'react'
import Papa from "papaparse";   // Library for parsing CSV


const ImportExport = ({filteredData, setFilteredData, name}) => {

    // Export to CSV
  const handleExport = () => {
    const csvContent = Papa.unparse(filteredData, {
      header: true,
    });

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "candidates.csv";
    link.click();
  };

  // Import from CSV
  const handleImport = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          setFilteredData((prev) => [...prev, ...result.data]);
        },
        error: (error) => {
          console.error("Error parsing CSV:", error);
        },
      });
    }
  };


  return (
    <div className="flex">
    <label className="text-md cursor-pointer hover:font-semibold duration-200 ms-12">
      Import {name}
      <input
        type="file"
        accept=".csv"
        onChange={handleImport}
        className="hidden"
      />
    </label>
    <p
      className="text-md hover:font-semibold duration-200 cursor-pointer ms-12"
      onClick={handleExport}
    >
      Export {name}
    </p>
  </div>
  )
}

export default ImportExport