import React from "react";
import Nav from '../../components/Nav/Nav';
import Footer from './Footer';

const ContactUs = () => {
  return (
    <div className="">
          <Nav bgColor="bg-white fixed shadow-md"/>

    <div className="bg-gray-100 min-h-screen py-12">
      <div className="container mx-auto px-6">
        {/* Header */}
        <header className="text-center mb-12 pt-28">
          <h1 className="text-4xl font-bold text-gray-800">Contact Us</h1>
          <p className="mt-4 text-lg text-gray-600">
            We are here to help! Whether you have questions about our services, need assistance, or want to provide feedback, feel free to reach out to us.
          
          </p>
        </header>

        <div className="flex flex-col md:flex-row md:-mx-4">
         
          {/* Contact Information Section */}
          <div className="w-full md:w-11/12 md:px-4">
            <div className="bg-white p-6 py-14 rounded-lg shadow-lg">
              <h2 className="text-2xl font-semibold text-gray-800 mb-4">Contact Information</h2>
              <p className="text-gray-600 mb-4">
                We're here to help! Feel free to reach out to us via phone, email, social media or visit our office at the following location.
             
              </p>
              <ul className="text-gray-700">
                <li className="mb-2">
                  <span className="font-semibold">Phone:</span> +91 88502 31081
                </li>
                <li className="mb-2">
                  <span className="font-semibold">Email:</span> info@projob.co.in
                </li>
                <li className="mb-2">
                  <span className="font-semibold">Address:</span> Raheja Platinum, off Andheri - Kurla Road, Sag Baug, Marol, Andheri East, Mumbai, Maharashtra 400059 info@projob.co.in
                </li>
              </ul>

              {/* Social Media Links */}
              <div className="mt-6">
                <h3 className="text-xl font-semibold text-gray-800 mb-2">Follow Us</h3>
                <div className="flex space-x-4">
                  <a href="#" className="text-blue-600 hover:text-blue-800">
                    {/* Use appropriate icons */}
                    <i className="fab fa-facebook-f"></i> 
                  </a>
                  <a href="#" className="text-blue-400 hover:text-blue-600">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a href="#" className="text-blue-700 hover:text-blue-900">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
           {/* Contact Form Section */}
          <div className="w-full md:w-1/2 md:px-4 mb-8 md:mb-0">
            <div className="bg-white p-6 px-8 py-7 rounded-lg shadow-lg">
              <h2 className="text-2xl font-semibold text-gray-800 mb-4">Send Us a Message</h2>
              <form>
                <div className="mb-4">
                 
                  <input
                    type="text"
                    id="name"
                    className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
                    placeholder="Your Name"
                    required
                  />
                </div>
                <div className="mb-4">
                 
                  <input
                    type="email"
                    id="email"
                    className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
                    placeholder="Your Email"
                    required
                  />
                </div>
                <div className="mb-4">
                 
                  <textarea
                    id="message"
                    rows="3"
                    className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:ring focus:ring-blue-300"
                    placeholder="Your Message"
                    required
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="w-full bg-blue-500 hover:bg-[#6ad61d] text-white py-2 px-4 rounded-lg focus:outline-none focus:bg-blue-600"
                >
                  Send Message
                </button>
              </form>
            </div>
          </div>
          
        </div>

        <div className="flex flex-col md:flex-row md:-mx-4">
             {/* Map Section */}
        
        <section className="w-full md:px-4">
          <h2 className="text-2xl font-semibold text-gray-800 py-10">Our Location</h2>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <iframe
              title="Office Location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.8354345093743!2d144.95373531532132!3d-37.81627917975195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65d43f33fb399%3A0x8e12d0170e4f6376!2sGoogle!5e0!3m2!1sen!2sus!4v1614153033795!5m2!1sen!2sus"
              width="100%"
              height="400"
              className="rounded-lg"
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
        </section>
      
        </div>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default ContactUs;
