import React, { useState, useEffect, useRef } from "react";
import { useUserContext } from "../../../../context/userContext";
import SelectLanguage from "./SelectLanguage";
import SearchableList from "../../../../components/Utility/SearchableList";
import { qualifications } from "./SelectOptions";
import AlternateNumber from "./AlternateNumber";
import { BsTypeBold } from "react-icons/bs";
import { BiItalic } from "react-icons/bi";
import { RiDoubleQuotesL } from "react-icons/ri";
import { MdFormatListBulleted } from "react-icons/md";
import { MdFormatListNumbered } from "react-icons/md";
import { FaAlignLeft } from "react-icons/fa";
import { FaAlignCenter } from "react-icons/fa";
import { FaAlignRight } from "react-icons/fa";
import { FaAlignJustify } from "react-icons/fa";
import { PiLinkSimpleBold } from "react-icons/pi";
import { PiLinkBreakBold } from "react-icons/pi";
import { FaCaretDown } from "react-icons/fa";
import { MdFormatIndentDecrease } from "react-icons/md";
import { MdFormatIndentIncrease } from "react-icons/md";
import { MdContentPaste } from "react-icons/md";
import { MdFormatClear } from "react-icons/md";
import { MdInsertPageBreak } from "react-icons/md";
import { BsTable } from "react-icons/bs";
import { FaQuestionCircle } from "react-icons/fa";
import { LuUndo2 } from "react-icons/lu";
import { GrRedo } from "react-icons/gr";

const baseUrl = process.env.REACT_APP_SERVER_API_URL || "http://localhost:8000";
function MyProfile({
  profileInfo,
  onChange,
  handleLanguageChange,
  onImageChange,
  ageoptions,
  industryOptions,
  experienceoptions,
  genderoption,
  qualificationoptions,
  salaryoptions,
  addNumberClick,
  handleAlternateNumber,
  removeNumberClick,
}) {
  const [preview, setPreview] = useState({ logoImage: "", coverImage: "" });
  const [searchTerm, setSearchTerm] = useState("");
  // const [selectedQualification, setSelectedQualification] = useState("");
  // const [specializations, setSpecializations] = useState([]);
  const { user } = useUserContext();

  useEffect(() => {
    if (profileInfo.logoImage && profileInfo.logoImage instanceof Blob) {
      const logoImageUrl = URL.createObjectURL(profileInfo.logoImage);
      setPreview((prev) => ({ ...prev, logoImage: logoImageUrl }));
    }
    if (profileInfo.coverImage && profileInfo.coverImage instanceof Blob) {
      const coverImageUrl = URL.createObjectURL(profileInfo.coverImage);
      setPreview((prev) => ({ ...prev, coverImage: coverImageUrl }));
    }
  }, [profileInfo.logoImage, profileInfo.coverImage]);
  // Remember to revoke the URL when the component unmounts or the image changes
  useEffect(() => {
    return () => {
      URL.revokeObjectURL(preview.logoImage);
      URL.revokeObjectURL(preview.coverImage);
    };
  }, [preview]);

  const selectedQualification = qualificationoptions.find(
    (q) => q.label === profileInfo.qualification
  );
  const specializations = selectedQualification
    ? selectedQualification.specializations
    : [];

  // console.log(selectedQualification);

  return (
    <div
      className="bg-white p-3 md:p-6 md:px-10 rounded-lg rounded-s-none"
      // id="scrollIntoView"
      // ref={targetDivRef}
    >
      <h2 className=" text-lg text-[#202124]  mb-6 font-bold">My Profile</h2>

      <div className="pb-5 flex mb-7 gap-4">
        <input
          type="file"
          name="logoImage"
          id="logoImage"
          onChange={(e) => onImageChange(e, "logoImage")}
          className="w-full hidden text-sm text-gray-900 py-2.5 px-4 border border-gray-300 rounded-lg focus:outline-none focus:border-[#6ad61d] focus:ring-[#6ad61d]"
        />
        <button
          type="button"
          onClick={() => document.getElementById("logoImage").click()}
          className="py-2 px-7 bg-[#6ad61d] hover:bg-blue-500 text-white rounded-lg"
        >
          Browse
        </button>
        {profileInfo?.logoImage && (
          <img
            className=" h-12"
            src={preview?.logoImage || `${baseUrl}/${profileInfo?.logoImage}`}
            alt="Logo Preview"
          />
        )}
      </div>

      <div className="">
        <div className="flex flex-wrap mx-2">
          <div className="mb-5 w-full md:w-1/2 px-2">
            <label
              htmlFor="name"
              className="block mb-2 text-sm font-bold text-gray-900 "
            >
              Full Name <span className="text-red-600">*</span>
            </label>
            <input
              type="text"
              name="name"
              value={profileInfo?.name || ""}
              onChange={onChange}
              className="block w-full p-5 bg-gray-100 border-gray-300 focus:outline-[#6ad61d] text-gray-900 border rounded-lg text-base focus:ring-[#6ad61d] focus:border-[#6ad61d] dark:bg-gray-100 dark:border-none dark:placeholder-gray-400 dark:gray-900 dark:focus:ring-[#6ad61d] dark:focus:border-[#6ad61d]"
              placeholder="Enter Full Name"
              required
            />
          </div>

          <div className="mb-5 w-full md:w-1/2 px-2">
            <label
              htmlFor="dob"
              className="block mb-2 text-sm font-bold text-gray-900"
            >
              Date Of Birth <span className="text-red-600">*</span>
            </label>
            <input
              type="date"
              name="dob"
              value={profileInfo?.dob || ""}
              onChange={onChange}
              placeholder="Enter Date of Birth"
              className="block w-full p-5  bg-gray-100 border-gray-300 focus:outline-[#6ad61d] text-gray-900 border rounded-lg text-base focus:ring-[#6ad61d] focus:border-[#6ad61d] dark:bg-gray-100 dark:border-none dark:placeholder-gray-400 dark:gray-900 dark:focus:ring-[#6ad61d] dark:focus:border-[#6ad61d]"
              required
            />
          </div>
        </div>

        <div className="flex flex-wrap mx-2">
          {/* Gender */}
          <div className="mb-5 w-full md:w-1/2 px-2">
            <label
              htmlFor="gender"
              className="block text-sm font-bold text-gray-900"
            >
              Gender <span className="text-red-600">*</span>
            </label>
            <select
              name="gender"
              value={profileInfo?.gender || ""}
              onChange={onChange}
              required
              className="block w-full p-5  bg-gray-100 border-gray-300 focus:outline-[#6ad61d] text-gray-900 border rounded-lg text-base focus:ring-[#6ad61d] focus:border-[#6ad61d] dark:bg-gray-100 dark:border-none dark:placeholder-gray-400 dark:gray-900 dark:focus:ring-[#6ad61d] dark:focus:border-[#6ad61d]"
            >
              <option value="">None</option>
              {genderoption.map((option) => (
                <option key={option.value} value={option.label}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-5 w-full md:w-1/2 px-2">
            <label
              htmlFor="age"
              className="block text-sm font-bold text-gray-900"
            >
              Age <span className="text-red-600">*</span>
            </label>
            <select
              name="age"
              value={profileInfo?.age || ""}
              onChange={onChange}
              required
              className="block w-full p-5  bg-gray-100 border-gray-300 focus:outline-[#6ad61d] text-gray-900 border rounded-lg text-base focus:ring-[#6ad61d] focus:border-[#6ad61d] dark:bg-gray-100 dark:border-none dark:placeholder-gray-400 dark:gray-900 dark:focus:ring-[#6ad61d] dark:focus:border-[#6ad61d]"
            >
              <option value="">None</option>
              {ageoptions.map((option) => (
                <option key={option.value} value={option.label}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="flex flex-wrap mx-2">
          <div className="mb-5 w-full md:w-1/2 px-2">
            <label
              htmlFor="phoneNumber"
              className="block mb-2 text-sm font-bold text-gray-900 "
            >
              Phone Number <span className="text-red-600">*</span>
            </label>
           
            <input
            type="text"
            name="phoneNumber"
            disabled
            value={"+" + profileInfo?.phoneNumber || ""}
            onChange={onChange}
            className="block w-full p-5 bg-gray-100 border-gray-300 focus:outline-[#6ad61d] text-gray-900 border rounded-lg text-base focus:ring-[#6ad61d] focus:border-[#6ad61d] dark:bg-gray-100 dark:border-none dark:placeholder-gray-400 dark:gray-900 dark:focus:ring-[#6ad61d] dark:focus:border-[#6ad61d]"
            placeholder="Enter Phone Number"
            required
            />
             
          </div>
        </div>

        <div className="mb-5 flex flex-wrap flex-col mx-2 ">
          <label
            htmlFor="phoneNumber"
            className="block mb-2 text-sm font-bold text-gray-900 "
          >
            Alternate Phone Number
          </label>
          {profileInfo?.alternateNumbers?.map((number, i) => (
            <AlternateNumber
              key={i}
              index={i}
              onChange={handleAlternateNumber}
              number={number}
              onRemove={removeNumberClick}
            />
          ))}
          {profileInfo.alternateNumbers.length && (
            <button
              className="max-w-44   text-[#6ad61d] bg-[#6ad61d23] rounded-lg transition duration-300 mt-4 ease-in-out focus:outline-none text-sm w-full sm:w-auto px-5 py-3 text-center dark:bg-[#6ad61d23] dark:hover:bg-[#6ad61d] dark:hover:text-white dark:focus:ring-[#6ad61d]"
              type="button"
              onClick={addNumberClick}
            >
              Add number
            </button>
          )}
        </div>

        <div className="mb-5 w-full md:w-1/2 px-2">
          <label
            htmlFor="email"
            className="block mb-2 text-sm font-bold text-gray-900"
          >
            Email <span className="text-red-600">*</span>
          </label>
          <input
            type="email"
            name="email"
            value={profileInfo?.email || ""}
            onChange={onChange}
            placeholder="Enter Email"
            className="block w-full p-5  bg-gray-100 border-gray-300 focus:outline-[#6ad61d] text-gray-900 border rounded-lg text-base focus:ring-[#6ad61d] focus:border-[#6ad61d] dark:bg-gray-100 dark:border-none dark:placeholder-gray-400 dark:gray-900 dark:focus:ring-[#6ad61d] dark:focus:border-[#6ad61d]"
            required
          />
        </div>

        <div className="flex flex-wrap mx-2">
          <SearchableList
            label="Qualification"
            name="qualification"
            value={profileInfo?.qualification || ""}
            onChange={onChange}
            array={qualificationoptions}
            required
          />
          <SearchableList
            label="Specialization"
            value={profileInfo.specialization || ""}
            onChange={onChange}
            name="specialization"
            array={specializations}
            required
          />

          {/* <div className="mb-5 w-full md:w-1/2 px-2">
            <label
              htmlFor="specialization"
              className="block mb-2 text-sm font-bold text-gray-900"
            >
              Specialization <span className="text-red-600">*</span>
            </label>
            <input
              type="text"
              name="specialization"
              value={user?.specialization || ""}
              onChange={onChange}
              placeholder="eg. Computer Science or IT"
              className="block w-full p-5  bg-gray-100 border-gray-300 focus:outline-[#6ad61d] text-gray-900 border rounded-lg text-base focus:ring-[#6ad61d] focus:border-[#6ad61d] dark:bg-gray-100 dark:border-none dark:placeholder-gray-400 dark:gray-900 dark:focus:ring-[#6ad61d] dark:focus:border-[#6ad61d]"
              required
            />
          </div> */}
        </div>

        <div className="flex flex-wrap mx-2 ">
          <SearchableList
            label="Experience Time"
            name="experience"
            value={profileInfo?.experience || ""}
            onChange={onChange}
            array={experienceoptions}
            required
          />

          <SearchableList
            label=" Salary Type"
            name="salaryType"
            value={profileInfo?.salaryType || ""}
            onChange={onChange}
            array={salaryoptions}
          />
        </div>

        <div className="flex flex-wrap mx-2">
          <div className="mb-5 w-full md:w-1/2 px-2">
            <label
              htmlFor="salary"
              className="block mb-2 text-sm font-bold text-gray-900 "
            >
              Salary(Monthly)
            </label>
            <input
              type="text"
              name="salary"
              value={profileInfo?.salary || ""}
              onChange={onChange}
              className="block w-full p-5 bg-gray-100 border-gray-300 focus:outline-[#6ad61d] text-gray-900 border rounded-lg text-base focus:ring-[#6ad61d] focus:border-[#6ad61d] dark:bg-gray-100 dark:border-none dark:placeholder-gray-400 dark:gray-900 dark:focus:ring-[#6ad61d] dark:focus:border-[#6ad61d]"
              placeholder="Enter Salary"
            />
          </div>
          <div className="mb-5 w-full md:w-1/2 px-2">
            <label
              htmlFor="ctc"
              className="block mb-2 text-sm font-bold text-gray-900 "
            >
              CTC(LPA)
            </label>
            <input
              type="text"
              name="ctc"
              value={profileInfo?.ctc || ""}
              onChange={onChange}
              className="block w-full p-5 bg-gray-100 border-gray-300 focus:outline-[#6ad61d] text-gray-900 border rounded-lg text-base focus:ring-[#6ad61d] focus:border-[#6ad61d] dark:bg-gray-100 dark:border-none dark:placeholder-gray-400 dark:gray-900 dark:focus:ring-[#6ad61d] dark:focus:border-[#6ad61d]"
              placeholder="Enter CTC eg. 5,00,000"
            />
          </div>
          <SearchableList
            label="Industry"
            name="industry"
            value={profileInfo?.industry || ""}
            onChange={onChange}
            array={industryOptions}
          />
          <div className="mb-5 w-full md:w-1/2 px-2">
            <label
              htmlFor="jobTitle"
              className="block  text-sm font-bold text-gray-900 "
            >
              Job title
            </label>
            <input
              type="text"
              name="jobTitle"
              value={profileInfo?.jobTitle || ""}
              onChange={onChange}
              className="block w-full p-5 bg-gray-100 border-gray-300 focus:outline-[#6ad61d] text-gray-900 border rounded-lg text-base focus:ring-[#6ad61d] focus:border-[#6ad61d] dark:bg-gray-100 dark:border-none dark:placeholder-gray-400 dark:gray-900 dark:focus:ring-[#6ad61d] dark:focus:border-[#6ad61d]"
              placeholder="eg. Software developer"
            />
          </div>
        </div>

        <div className="mb-5 w-full md:w-1/2 px-2 relative">
          <label
            htmlFor="languages"
            className="block text-sm font-bold text-gray-900"
          >
            Language
          </label>

          <SelectLanguage
            value={profileInfo?.languages}
            onChange={handleLanguageChange}
          />
        </div>

        <div className="flex flex-wrap mx-2">
          <label
            htmlFor="description"
            className="block text-sm font-bold text-gray-900"
          >
            Professional Summary
          </label>
          <div className="w-full bg-gray-100 rounded-b-lg dark:bg-white">
            {/* Old */}
            {/* <textarea
              id="editor"
              rows="10"
              name="description"
              value={profileInfo?.description || ""}
              onChange={onChange}
              className="block w-full p-3 text-sm text-black bg-white focus:outline-gray-200   border"
              placeholder=""
              required
            ></textarea> */}

            {/* New */}
            <form className="flex flex-wrap">
            <div className="flex flex-wrap w-full  border  bg-white border-gray-300">
              <div className="flex flex-wrap w-full items-start  py-1 border-b  ">
                <button
                  id="dropdownDefaultButton"
                  data-dropdown-toggle="dropdown"
                  className="text-gray-600 text-sm px-2 py-1  focus:ring-2 focus:outline-none  dark:focus:ring-blue-800 inline-flex items-start "
                  type="button"
                >
                  file <FaCaretDown />
                </button>

                <div
                  id="dropdown"
                  className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
                >
                  <ul
                    className="py-2 text-sm text-gray-700 dark:text-gray-200"
                    aria-labelledby="dropdownDefaultButton"
                  >
                    <li>print</li>
                  </ul>
                </div>

                <button
                  id="dropdownDefaultButton"
                  data-dropdown-toggle="dropdown"
                  className="text-gray-600 text-sm px-2 py-1 focus:ring-2 focus:outline-none  dark:focus:ring-blue-800 inline-flex items-start"
                >
                  Edit <FaCaretDown />
                </button>

                <div
                  id="dropdown"
                  className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
                >
                  <ul
                    className="py-2 text-sm text-gray-700 dark:text-gray-200"
                    aria-labelledby="dropdownDefaultButton"
                  >
                    <li>
                      <a
                        href="#"
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        print
                      </a>
                    </li>
                  </ul>
                </div>

                <button
                  id="dropdownDefaultButton"
                  data-dropdown-toggle="dropdown"
                  className="text-gray-600 text-sm px-2 py-1  focus:ring-2 focus:outline-none  dark:focus:ring-blue-800 inline-flex items-start"
                  type="button"
                >
                  View <FaCaretDown />
                </button>

                <div
                  id="dropdown"
                  className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
                >
                  <ul
                    className="py-2 text-sm text-gray-700 dark:text-gray-200"
                    aria-labelledby="dropdownDefaultButton"
                  >
                    <li>
                      <a
                        href="#"
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        print
                      </a>
                    </li>
                  </ul>
                </div>
                <button
                  id="dropdownDefaultButton"
                  data-dropdown-toggle="dropdown"
                  className="text-gray-600 text-sm px-2 py-1  focus:ring-2 focus:outline-none  dark:focus:ring-blue-800  inline-flex items-start"
                  type="button"
                >
                  Insert <FaCaretDown />
                </button>

                <div
                  id="dropdown"
                  className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
                >
                  <ul
                    className="py-2 text-sm text-gray-700 dark:text-gray-200"
                    aria-labelledby="dropdownDefaultButton"
                  >
                    <li>
                      <a
                        href="#"
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        print
                      </a>
                    </li>
                  </ul>
                </div>

                <button
                  id="dropdownDefaultButton"
                  data-dropdown-toggle="dropdown"
                  className="text-gray-600 text-sm px-2 py-1  focus:ring-2 focus:outline-none  dark:focus:ring-blue-800  inline-flex items-start"
                  type="button"
                >
                  Format <FaCaretDown />
                </button>

                <div
                  id="dropdown"
                  className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
                >
                  <ul
                    className="py-2 text-sm text-gray-700 dark:text-gray-200"
                    aria-labelledby="dropdownDefaultButton"
                  >
                    <li>
                      <a
                        href="#"
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        print
                      </a>
                    </li>
                  </ul>
                </div>
                <button
                  id="dropdownDefaultButton"
                  data-dropdown-toggle="dropdown"
                  className="text-gray-600 text-sm px-2 py-1  focus:ring-2 focus:outline-none  dark:focus:ring-blue-800 inline-flex items-start"
                  type="button"
                >
                  Tools <FaCaretDown />
                </button>

                <div
                  id="dropdown"
                  className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
                >
                  <ul
                    className="py-2 text-sm text-gray-700 dark:text-gray-200"
                    aria-labelledby="dropdownDefaultButton"
                  >
                    <li>
                      <a
                        href="#"
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        print
                      </a>
                    </li>
                  </ul>
                </div>

                <button
                  id="dropdownDefaultButton"
                  data-dropdown-toggle="dropdown"
                  className="text-gray-600 text-sm px-2 py-1  focus:ring-2 focus:outline-none  dark:focus:ring-blue-800  inline-flex items-start"
                  type="button"
                >
                  Table <FaCaretDown />
                </button>

                <div
                  id="dropdown"
                  className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
                >
                  <ul
                    className="py-2 text-sm text-gray-700 dark:text-gray-200"
                    aria-labelledby="dropdownDefaultButton"
                  >
                    <li>
                      <a
                        href="#"
                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        print
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* first icon row */}
              <div className="w-full flex flex-wrap items-center justify-between px-3 py-1  bg-gray-100 border-gray-300 sm:divide-x sm:rtl:divide-x-reverse text-sm sm:text-base">
                <div className="flex flex-wrap items-center divide-gray-200 sm:divide-x sm:rtl:divide-x-reverse dark:divide-gray-600 text-sm sm:text-base">
                  <div className=" flex  flex-wrap  items-center space-x-1 rtl:space-x-reverse text-sm sm:text-base">
                    <form className="max-w-sm text-sm sm:text-base">
                      <select
                        id="countries"
                        className="text-gray-600 bg-white  focus:ring-2 focus:outline-none text-sm px-1 text-center inline-flex items-center dark:bg-white dark:hover:bg-white dark:focus:ring-blue-800"
                      >
                        <FaCaretDown />
                        <option selected>Heading 1</option>
                        <option value="US">United States</option>
                        <option value="CA">Canada</option>
                        <option value="FR">France</option>
                        <option value="DE">Germany</option>
                      </select>
                    </form>

                    <button
                      data-tooltip-target="tooltip-default"
                      type="button"
                      className=" text-gray-600 p-2 rounded cursor-pointer  hover:bg-gray-100  focus:ring-2 focus:outline-none text-sm px-2 py-1 text-center inline-flex items-center dark:focus:ring-blue-800 dark:hover:ring-blue-800"
                    >
                      <BsTypeBold size={20} />
                    </button>

                    <div
                      id="tooltip-default"
                      role="tooltip"
                      className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
                    >
                      Bold (ctr+B)
                      <div className="tooltip-arrow" data-popper-arrow></div>
                    </div>

                    <button
                      type="button"
                      className=" text-gray-600 p-2 rounded cursor-pointer  hover:bg-gray-100  focus:ring-2 focus:outline-none text-sm px-2 py-1 text-center inline-flex items-center dark:focus:ring-blue-800"
                    >
                      <BiItalic size={20} />
                    </button>
                    <button
                      type="button"
                      className=" text-gray-600 p-2 rounded cursor-pointer  hover:bg-gray-100  focus:ring-2 focus:outline-none text-sm px-2 py-1 text-center inline-flex items-center dark:focus:ring-blue-800"
                    >
                      <RiDoubleQuotesL size={20} />
                    </button>
                    <button
                      type="button"
                      className=" text-gray-600 p-2 rounded cursor-pointer  hover:bg-gray-100  focus:ring-2 focus:outline-none text-sm px-2 py-1 text-center inline-flex items-center dark:focus:ring-blue-800"
                    >
                      <MdFormatListBulleted size={20} />
                    </button>
                    <button
                      type="button"
                      className=" text-gray-600 p-2 rounded cursor-pointer  hover:bg-gray-100  focus:ring-2 focus:outline-none text-sm px-2 py-1 text-center inline-flex items-center dark:focus:ring-blue-800"
                    >
                      <MdFormatListNumbered size={20} />
                    </button>

                    <button
                      type="button"
                      className=" text-gray-600 p-2 rounded cursor-pointer  hover:bg-gray-100  focus:ring-2 focus:outline-none text-sm px-2 py-1 text-center inline-flex items-center dark:focus:ring-blue-800"
                    >
                      <FaAlignLeft />
                    </button>
                    <button
                      type="button"
                      className=" text-gray-600 p-2 rounded cursor-pointer  hover:bg-gray-100  focus:ring-2 focus:outline-none text-sm px-2 py-1 text-center inline-flex items-center dark:focus:ring-blue-800"
                    >
                      <FaAlignCenter />
                    </button>
                    <button
                      type="button"
                      className=" text-gray-600 p-2 rounded cursor-pointer  hover:bg-gray-100  focus:ring-2 focus:outline-none text-sm px-2 py-1 text-center inline-flex items-center dark:focus:ring-blue-800"
                    >
                      <FaAlignRight />
                    </button>
                    <button
                      type="button"
                      className=" text-gray-600 p-2 rounded cursor-pointer  hover:bg-gray-100  focus:ring-2 focus:outline-none text-sm px-2 py-1 text-center inline-flex items-center dark:focus:ring-blue-800"
                    >
                      <FaAlignJustify />
                    </button>
                    <button
                      type="button"
                      className=" text-gray-600 p-2 rounded cursor-pointer  hover:bg-gray-100  focus:ring-2 focus:outline-none text-sm px-2 py-1 text-center inline-flex items-center dark:focus:ring-blue-800"
                    >
                      <PiLinkSimpleBold size={20} />
                    </button>
                    <button
                      type="button"
                      className=" text-gray-600 p-2 rounded cursor-pointer  hover:bg-gray-100  focus:ring-2 focus:outline-none text-sm px-2 py-1 text-center inline-flex items-center dark:focus:ring-blue-800"
                    >
                      <PiLinkBreakBold size={20} />
                    </button>
                    <button
                      type="button"
                      className=" text-gray-600 p-2 rounded cursor-pointer  hover:bg-gray-100  focus:ring-2 focus:outline-none text-sm px-2 py-1 text-center inline-flex items-center dark:focus:ring-blue-800"
                    >
                      <LuUndo2 size={20} />
                    </button>
                    <button
                      type="button"
                      className=" text-gray-600 p-2 rounded cursor-pointer  hover:bg-gray-100  focus:ring-2 focus:outline-none text-sm px-2 py-1 text-center inline-flex items-center dark:focus:ring-blue-800"
                    >
                      <GrRedo size={20} />
                    </button>
                  </div>
                </div>
              </div>

              {/* Second icon row */}
              <div className=" flex flex-wrap w-full items-center justify-between px-3  border-b bg-gray-100 border-gray-300 sm:divide-x sm:rtl:divide-x-reverse text-sm sm:text-base">
                <div className=" flex flex-wrap items-center divide-gray-200 sm:divide-x sm:rtl:divide-x-reverse dark:divide-gray-600 text-sm sm:text-base">
                  <div className="flex flex-wrap items-center space-x-1 rtl:space-x-reverse text-sm sm:text-base">
                    <form className="flex flex-wrap max-w-sm text-sm sm:text-base">
                      <select
                        id="countries"
                        className="text-gray-600 bg-white  focus:ring-2 focus:outline-none text-sm px-1 text-center inline-flex items-center dark:bg-white dark:hover:bg-white dark:focus:ring-blue-800"
                      >
                        <option selected>Arial Blank</option>
                        <option value="US">Monospace</option>
                        <option value="CA">arial</option>
                        <option value="FR">Times in romans</option>
                        <option value="DE">impacts</option>
                        <option value="DE">Terminal</option>
                      </select>
                    </form>
                    <form className="max-w-sm ">
                      <select
                        id="countries"
                        className="text-gray-600 bg-white px-6 ms-2 focus:ring-2 focus:outline-none text-sm text-center inline-flex items-center dark:bg-white dark:hover:bg-white dark:focus:ring-blue-800"
                      >
                        <option selected>12pt</option>
                        <option value="US">14pt</option>
                        <option value="CA">16pt</option>
                        <option value="FR">18pt</option>
                        <option value="DE">20pt</option>
                      </select>
                    </form>
                    <button
                      data-tooltip-target="tooltip-default"
                      type="button"
                      className=" text-gray-600 p-2 rounded cursor-pointer  hover:bg-gray-100  focus:ring-2 focus:outline-none text-sm px-2 py-1 text-center inline-flex items-center dark:focus:ring-blue-800"
                    >
                      <MdFormatIndentDecrease size={20} />
                    </button>

                    <div
                      id="tooltip-default"
                      role="tooltip"
                      className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
                    >
                      Decrease Indent (ctr+B)
                      <div className="tooltip-arrow" data-popper-arrow></div>
                    </div>

                    <button
                      type="button"
                      className=" text-gray-600 p-2 rounded cursor-pointer  hover:bg-gray-100  focus:ring-2 focus:outline-none text-sm px-2 py-1 text-center inline-flex items-center dark:focus:ring-blue-800"
                    >
                      <MdFormatIndentIncrease size={20} />
                    </button>
                    <button
                      type="button"
                      className=" text-gray-600 p-2 rounded cursor-pointer  hover:bg-gray-100  focus:ring-2 focus:outline-none text-sm px-2 py-1 text-center inline-flex items-center dark:focus:ring-blue-800"
                    >
                      <MdContentPaste size={20} />
                    </button>
                    <button
                      type="button"
                      className=" text-gray-600 p-2 rounded cursor-pointer  hover:bg-gray-100  focus:ring-2 focus:outline-none text-sm px-2 py-1 text-center inline-flex items-center dark:focus:ring-blue-800"
                    >
                      <MdFormatClear size={20} />
                    </button>
                    <button
                      type="button"
                      className=" text-gray-600 p-2 rounded cursor-pointer  hover:bg-gray-100  focus:ring-2 focus:outline-none text-sm px-2 py-1 text-center inline-flex items-center dark:focus:ring-blue-800"
                    >
                      <MdInsertPageBreak size={20} />
                    </button>

                    <button
                      type="button"
                      className=" text-gray-600 p-2 rounded cursor-pointer  hover:bg-gray-100  focus:ring-2 focus:outline-none text-sm px-2 py-1 text-center inline-flex items-center dark:focus:ring-blue-800"
                    >
                      <BsTable size={20} />
                    </button>
                    <button
                      type="button"
                      className=" text-gray-600 p-2 rounded cursor-pointer  hover:bg-gray-100  focus:ring-2 focus:outline-none text-sm px-2 py-1 text-center inline-flex items-center dark:focus:ring-blue-800"
                    >
                      <FaQuestionCircle size={20} />
                    </button>
                  </div>
                </div>
              </div>
              <div className="w-full bg-gray-100 rounded-b-lg dark:bg-white">
                <textarea
                  id="editor"
                  rows="10"
                  name="description"
                  value={profileInfo?.description || ""}
                  onChange={onChange}
                  className="block w-full p-3 text-sm text-black bg-white focus:outline-gray-200 border"
                  placeholder=""
                  required
                  // className="block w-full px-0 text-sm bg-white  dark:text-white dark:placeholder-gray-400 border-none"
                 
                ></textarea>
              </div>
            </div>
          </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyProfile;
