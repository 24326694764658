import React, { useEffect, useState } from "react";
import PostJob from "../../components/Admin/PostJob";
import { useAdminContext } from "../../context/adminContext";

export default function Jobs() {
  const [isAddJobOn, setIsAddJobOn] = useState(false);
  const { adminData, jobs } = useAdminContext();
  const [filteredJobs, setFilteredJobs] = useState(jobs);

  // useEffect(() => {
  //   const fetchJobs = async () => {
  //     if (adminToken) {
  //       const res = await fetchPostedJobs(adminToken);
  //       console.log(res);
  //       if (res && res.data && res.data.allJobs) {
  //         setJobs(res.data.allJobs);
  //         setFilteredJobs(res.data.allJobs);
  //       }
  //     }
  //   };
  //   fetchJobs();
  // }, [setFilteredJobs, adminToken, adminData, isAddJobOn]);

  const filterAllJob = () => {
    setFilteredJobs(jobs);
  };

  const filterMyJob = () => {
    const myJobs = jobs.filter((job) => job.createdBy === adminData._id);
    setFilteredJobs(myJobs);
  };

  return (
    <div className="relative w-screen md:w-full overflow-hidden items-center h-full bg-[#f5f7fc]">
      <div className="w-full text-white bg-[#394e86] md:p-5 flex justify-between">
        <p className="text-lg cursor-pointer ms-12">All Jobs</p>
      </div>

    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <button
          onClick={() => setIsAddJobOn(!isAddJobOn)}
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          {isAddJobOn ? "Close Add Job Form" : "Add New Job"}
        </button>
        <div>
          <button
            onClick={filterAllJob}
            className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
          >
            All Jobs
          </button>
          <button
            onClick={filterMyJob}
            className="bg-gray-300 text-gray-700 px-4 py-2 rounded"
          >
            My Jobs
          </button>
        </div>
      </div>

      {isAddJobOn && <PostJob setIsAddJobOn = {setIsAddJobOn}/>}

      <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-md">
        <thead className="bg-gray-200">
          <tr>
            <th className="py-3 px-4 text-left">Employer Name</th>
            <th className="py-3 px-4 text-left">Posting Date</th>
            <th className="py-3 px-4 text-left">Location</th>
            <th className="py-3 px-4 text-left">Salary</th>
            <th className="py-3 px-4 text-left">Number of Applicants</th>
            <th className="py-3 px-4 text-left">shortlisted</th>
            <th className="py-3 px-4 text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredJobs?.length > 0 ? (
            filteredJobs.map((job) => (
              <tr key={job._id}>
                <td className="py-3 px-4">{job.company}</td>
                <td className="py-3 px-4">{new Date(job.postingDate).toLocaleDateString()}</td>
                <td className="py-3 px-4">{job.jobLocation}</td>
                <td className="py-3 px-4">{job.minSalary + '-' + job.maxSalary}₹/month</td>
                <td className="py-3 px-4">{Array.isArray(job.applications) ? job.applications.length : 0}</td>
                <td className="py-3 px-4">{Array.isArray(job.shortlisted) ? job.shortlisted.length:0}</td>
                <td className="py-3 px-4">
                  <button className="bg-blue-500 text-white px-4 py-1 rounded mr-2">View</button>
                  <button className="bg-yellow-500 text-white px-4 py-1 rounded">Edit</button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" className="py-3 px-4 text-center">No Jobs Found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  </div>
  );
}