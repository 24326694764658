import React, { useEffect, useState } from "react";

//Filter Candidates
export function CandidateFilterBar({ data, onFilter }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [location, setLocation] = useState("");
  const [jobRole, setJobRole] = useState("");
  const [education, setEducation] = useState("");

  

  useEffect(()=>{
    onFilter({ searchTerm, location, jobRole, education })
    // eslint-disable-next-line
  }, [searchTerm,location, jobRole, education ])


  // Get unique values for dropdown options
  const locations = Array.from(new Set(data?.map(candidate => candidate.location.city)));
  const jobRoles = Array.from(new Set(data?.map(candidate => candidate.currentJobRole)));
  const educations = Array.from(new Set(data?.map(candidate => candidate.highestEducation)));

  return (
    <div className="flex gap-4 m-2 p-2 mx-auto bg-gray-100 w-[80%]">
      <input
        type="text"
        placeholder="Search by name, email, etc."
        value={searchTerm}
        onChange={(e) =>   setSearchTerm(e.target.value)}
        className="px-2 border rounded w-1/3"
      />
      <select
        value={location}
        onChange={(e) => setLocation(e.target.value)}
        className="px-2 border rounded"
      >
        <option value="">All Locations</option>
        {locations?.map((loc, index) => (
          <option key={index} value={loc}>
            {loc}
          </option>
        ))}
      </select>
      <select
        value={jobRole}
        onChange={(e) => setJobRole(e.target.value)}
        className="px-2 border rounded"
      >
        <option value="">All Job Roles</option>
        {jobRoles?.map((role, index) => (
          <option key={index} value={role}>
            {role}
          </option>
        ))}
      </select>
      <select
        value={education}
        onChange={(e) => setEducation(e.target.value)}
        className="px-2 border rounded"
      >
        <option value="">All Educations</option>
        {educations?.map((edu, index) => (
          <option key={index} value={edu}>
            {edu}
          </option>
        ))}
      </select>
    
    </div>
  );
}


//Filter Employers
export function EmployerFilterBar({ data, onFilter }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [location, setLocation] = useState("");
  const [selectedPackage, setSelectedPackage] = useState("");
  

  

  useEffect(()=>{
    onFilter({ searchTerm, location, Package:selectedPackage})
    // eslint-disable-next-line
  }, [searchTerm,location, selectedPackage ])


  // Get unique values for dropdown options
  const locations = Array.from(new Set(data?.map(employer => employer.location.city)));
  const packages = Array.from(new Set(data?.map(employer => employer.Package)));

  return (
    <div className="flex gap-4 m-2 p-2 mx-auto bg-gray-100 w-[80%]">
      <input
        type="text"
        placeholder="Search by name, email, etc."
        value={searchTerm}
        onChange={(e) =>   setSearchTerm(e.target.value)}
        className="px-2 border rounded w-1/3"
      />

      <select
        value={location}
        onChange={(e) => setLocation(e.target.value)}
        className="px-2 border rounded"
      >
        <option value="">All Locations</option>
        {locations?.map((loc, index) => (
          <option key={index} value={loc}>
            {loc}
          </option>
        ))}
        
      </select>
     
      <select
        value={selectedPackage}
        onChange={(e) => setSelectedPackage(e.target.value)}
        className="px-2 border rounded"
      >
        <option value="">Select min Package</option>
        {packages?.map((Package, index) => (
          <option key={index} value={Package}>
            {Package}
          </option>
        ))}
      </select>
    
    </div>
  );
}
