import React, { useEffect, useRef, useState } from "react";
import { SlOptionsVertical } from "react-icons/sl";
import {
  deleteAdmin,
  deleteCandidate,
  deleteEmployer,
  
} from "../../api/admin/axios";
import RegisterAdmin from "./RegisterAdmin";
import { useAdminContext } from "../../context/adminContext";
import EmployerEditor from "./EmployerEditor";
import CandidateEditor from "../Candidate/CandidateEditor";

const AdminOptions = ({
  data,
  employerModule,
  candidateModule,
  setFilteredCandidates,
  setIsEmployerDetailsOn,
  setFilteredEmployers,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isApproved, setIsApproved] = useState(true);
  const dropdownRef = useRef(null);
  const { setAllAdmins, setAllEmployers, setAllCandidates } = useAdminContext();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  const toggleApprove = () => {
    setIsApproved(prev => !prev)
  }

  const toggleOptions = () => {
    setIsOpen(!isOpen);
  };

  const onEdit = async () => {
    setIsEditing(true);
    setIsOpen(false);
  };

  const onDelete = async () => {
    if (!employerModule && !candidateModule) {
      const res = await deleteAdmin(data?._id);
      if (res?.data?.success) {
        setAllAdmins(res?.data?.allAdmin);
        setIsOpen(false);
        setIsEditing(false);
      }
    } else if (candidateModule) {
      const res = await deleteCandidate(data?._id);
      if (res?.data?.success) {
        setAllCandidates(res?.data?.allCandidates);
        setIsOpen(false);
        setFilteredCandidates(res?.data?.allCandidates);
      }
    } else  {
      const res = await deleteEmployer(data?._id);
      if (res?.data?.success) {
        setAllEmployers(res?.data?.allEmployers);
        setIsOpen(false);
        setFilteredEmployers(res?.data?.allEmployers);
      }
    } 
  };

  const onViewDetails = () => {
    setIsEmployerDetailsOn(true);
    setIsOpen(false);
  };

  return (
    <div className="relative inline-block" ref={dropdownRef}>
      <SlOptionsVertical
        onClick={toggleOptions}
        size={20}
        className="hover:bg-gray-300 p-1 rounded-full cursor-pointer active:bg-gray-200"
      />

      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 border bg-white rounded-md shadow-2xl py-1 z-10">
          {employerModule && (
            <button
              onClick={onViewDetails} // Change this to onViewDetails
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
            >
              View
            </button>
          )}
          <button
            onClick={onEdit}
            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
          >
            Edit
          </button>
          <button
            onClick={onDelete}
            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
          >
            Delete
          </button>

           <button
            onClick={toggleApprove}
            className={`block px-4 py-2 text-sm ${isApproved ? "text-green-400":"text-red-400"} hover:bg-gray-100 w-full text-left`}
          >
            {!isApproved ?"Disapprove":"Approve"}
          </button>


          {/* status button */} 
          {/* <button
            onClick={onDelete}
            className={`block px-4 py-2 text-sm ${data.isApproved ? "text-red-400" : "text-green-400"} hover:bg-gray-100 w-full text-left`}
          >
            {data.isApproved ? "Disapprove" : "Approve" }
          </button> */}


          {/* Add more options as needed */}
        </div>
      )}


      {!employerModule && !candidateModule && isEditing && (
        <div className="fixed inset-0 h-screen w-full flex flex-col ">
          <RegisterAdmin setIsAddAdminOn={setIsEditing} data={data} editing />
        </div>
      )}

      {employerModule && isEditing && (
        <div className="fixed inset-0 h-screen w-full flex flex-col ">
          <EmployerEditor
            employerData={data}
            setIsEditing={setIsEditing}
            setFilteredEmployers={setFilteredEmployers}
           
          />
        </div>
      )}

      {candidateModule && isEditing && (
        <div className="fixed inset-0 h-screen w-full flex flex-col ">
          <CandidateEditor setIsEditing={setIsEditing} data={data} editing />
        </div>
      )}
    </div>
  );
};

export default AdminOptions;