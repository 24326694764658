import React, { useEffect, useState } from "react";
import { useAdminContext } from "../../context/adminContext";
import Loader from "../../components/Utility/Loader";
import AdminOptions from "../../components/Admin/AdminOptions";
import {CandidateFilterBar} from "./FilterBar";
import ImportExport from "../../components/Admin/ImportExport";

// const data=[  {
//     "_id": "1",
//     "name": "John Doe",
//     "email": "john.doe@example.com",
//     "phoneNumber": "123-456-7890",
//     "location": { "city": "New York" },
//     "highestEducation": "Bachelor's in Computer Science",
//     "currentJobRole": "Software Engineer",
//     "isApproved": false
//   },
//   {
//     "_id": "2",
//     "name": "Jane Smith",
//     "email": "jane.smith@example.com",
//     "phoneNumber": "987-654-3210",
//     "location": { "city": "San Francisco" },
//     "highestEducation": "Master's in Data Science",
//     "currentJobRole": "Data Analyst",
//     "isApproved": true
//   },
//   {
//     "_id": "3",
//     "name": "Alex Johnson",
//     "email": "alex.johnson@example.com",
//     "phoneNumber": "456-123-7890",
//     "location": { "city": "Chicago" },
//     "highestEducation": "Bachelor's in Information Technology",
//     "currentJobRole": "Project Manager",
//     "isApproved": false
//   },
//   {
//     "_id": "4",
//     "name": "Emily Davis",
//     "email": "emily.davis@example.com",
//     "phoneNumber": "321-654-0987",
//     "location": { "city": "Austin" },
//     "highestEducation": "Bachelor's in Business Administration",
//     "currentJobRole": "HR Specialist",
//     "isApproved": true
//   },
//   {
//     "_id": "5",
//     "name": "Michael Brown",
//     "email": "michael.brown@example.com",
//     "phoneNumber": "654-321-7890",
//     "location": { "city": "Seattle" },
//     "highestEducation": "Ph.D. in Computer Science",
//     "currentJobRole": "Research Scientist",
//     "isApproved": false
//   },
//   {
//     "_id": "6",
//     "name": "Sarah Wilson",
//     "email": "sarah.wilson@example.com",
//     "phoneNumber": "789-456-1230",
//     "location": { "city": "Denver" },
//     "highestEducation": "Master's in Software Engineering",
//     "currentJobRole": "Full-Stack Developer",
//     "isApproved": true
//   },
//   {
//     "_id": "7",
//     "name": "David Miller",
//     "email": "david.miller@example.com",
//     "phoneNumber": "456-789-1230",
//     "location": { "city": "Miami" },
//     "highestEducation": "Bachelor's in Mechanical Engineering",
//     "currentJobRole": "Mechanical Engineer",
//     "isApproved": false
//   },
//   {
//     "_id": "8",
//     "name": "Jessica Lee",
//     "email": "jessica.lee@example.com",
//     "phoneNumber": "123-098-4567",
//     "location": { "city": "Boston" },
//     "highestEducation": "Master's in Cybersecurity",
//     "currentJobRole": "Cybersecurity Analyst",
//     "isApproved": true
//   },
//   {
//     "_id": "9",
//     "name": "Daniel White",
//     "email": "daniel.white@example.com",
//     "phoneNumber": "890-123-4567",
//     "location": { "city": "Los Angeles" },
//     "highestEducation": "Bachelor's in Mathematics",
//     "currentJobRole": "Data Scientist",
//     "isApproved": false
//   },
//   {
//     "_id": "10",
//     "name": "Laura Martinez",
//     "email": "laura.martinez@example.com",
//     "phoneNumber": "321-876-5432",
//     "location": { "city": "Las Vegas" },
//     "highestEducation": "Master's in Finance",
//     "currentJobRole": "Financial Analyst",
//     "isApproved": true
//   }
// ]


export default function CandidateModule() {
  const { allCandidates} = useAdminContext();
  const [filteredCandidates, setFilteredCandidates] = useState(allCandidates);
  const [isLoading,] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);



  const handleRowCheckboxChange = (candidateId) => {
    if (selectedRows.includes(candidateId)) {
      setSelectedRows(selectedRows.filter((id) => id !== candidateId));
    } else {
      setSelectedRows([...selectedRows, candidateId]);
    }
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      setSelectedRows(filteredCandidates.map((candidate) => candidate._id));
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    if (selectedRows.length === filteredCandidates.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedRows, filteredCandidates]);

  //Filter Handler
  const handleFilter = ({ searchTerm, location, jobRole, education }) => {
    let filteredData = allCandidates;

    if (searchTerm) {                                   // searchFrom name, email and phoneNumber only
      filteredData = filteredData.filter((candidate) => ["name", "email", "phoneNumber"].some((key) =>
          String(candidate[key]).toLowerCase().includes(searchTerm.toLowerCase())
        
      ));
    }
    if (location) {
      filteredData = filteredData.filter((candidate) => candidate.location.city === location);
    }
    if (jobRole) {
      filteredData = filteredData.filter((candidate) => candidate.currentJobRole === jobRole);
    }
    if (education) {
      filteredData = filteredData.filter((candidate) => candidate.highestEducation === education);
    }

    setFilteredCandidates(filteredData);
  };

  
  return (
    <div className="relative w-screen md:w-full overflow-hidden items-center h-full bg-[#f5f7fc]">
      <div className="w-full text-white bg-[#394e86] md:p-5 flex justify-between">
        <p className="text-lg cursor-pointer ms-12">Candidates</p>
        <ImportExport 
          filteredData = {filteredCandidates}
          setFilteredData={setFilteredCandidates}
          name={"Candidates"}
        />
      </div>

      {isLoading && <Loader />}

      <CandidateFilterBar data={allCandidates} onFilter={handleFilter} />

      <div className="w-full h-full overflow-x-auto p-1">
        <table className="w-full divide-gray-200 overflow-x-auto">
          <thead className="bg-gray-800 text-center">
            <tr>
              <th className="px-6 py-3 text-xs font-medium text-white uppercase tracking-wider">
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                  className="w-4 h-4 text-blue-600 cursor-pointer rounded border-gray-300 focus:ring-blue-500"
                />
              </th>
              <th></th>
              <th className="px-6 py-3 text-xs font-medium text-white uppercase tracking-wider">
                S.no.
              </th>
              <th className="px-6 py-3 text-xs font-medium text-white uppercase tracking-wider">
                Name
              </th>
              <th className="px-6 py-3 text-xs font-medium text-white uppercase tracking-wider">
                Email
              </th>
              <th className="px-6 py-3 text-xs font-medium text-white uppercase tracking-wider">
                Phone Number
              </th>
              <th className="px-6 py-3 text-xs font-medium text-white uppercase tracking-wider">
                Location
              </th>
              <th className="px-6 py-3 text-xs font-medium text-white uppercase tracking-wider">
                Highest Education
              </th>
              <th className="px-6 py-3 text-xs font-medium text-white uppercase tracking-wider">
                Current Job Role
              </th>
              <th className="px-6 py-3 text-xs font-medium text-white uppercase tracking-wider">
                Options
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {
            filteredCandidates.length ? <RenderCandidateData 
                                  filteredCandidates = {filteredCandidates} 
                                  setFilteredCandidates={setFilteredCandidates}
                                  selectedRows = {selectedRows}
                                  handleRowCheckboxChange={handleRowCheckboxChange}
                                  />
                                  : 
                                  <p className="font-semibold w-full divide-gray-200 text-lg ">No Candidate Found With this Data</p>
            } 
          </tbody>
        </table>
      </div>
    </div>
  );
}


const RenderCandidateData = ({filteredCandidates, setFilteredCandidates, selectedRows, handleRowCheckboxChange}) => {

  return (
   <>
     {filteredCandidates?.map((candidate, index) => (
      <tr key={candidate._id} className="hover:bg-gray-50">
        <td className="px-1 py-4 text-center text-sm text-gray-900">
          <input
            type="checkbox"
            checked={selectedRows.includes(candidate._id)}
            onChange={() => handleRowCheckboxChange(candidate._id)}
            className="w-4 h-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
          />
        </td>
        <td>
          <p
            className={`rounded-full flex items-center w-3 h-3 justify-center ${
              candidate?.isApproved ? "bg-green-500" : "bg-red-500"
            }`}
          ></p>
        </td>
        <td className="px-1 py-4 text-center text-sm text-gray-900">
          {index + 1}
        </td>
        <td className="px-1 py-4 text-center text-sm font-medium text-gray-900">
          {candidate.name}
        </td>
        <td className="px-1 py-4 text-center text-sm text-gray-500">
          {candidate.email}
        </td>
        <td className="px-1 py-4 text-center text-sm text-gray-500">
          {candidate.phoneNumber || "no number"}
        </td>
        <td className="px-1 py-4 text-center text-sm text-gray-500">
          {candidate.location?.city || "Location"}
        </td>
        <td className="px-1 py-4 text-center text-sm text-gray-500">
          {candidate.highestEducation || "Education"}
        </td>
        <td className="px-1 py-4 text-center text-sm text-gray-500">
          {candidate.currentJobRole || "Job role"}
        </td>
        <td className="px-1 py-4 text-center text-sm text-gray-500">
          <AdminOptions
            data={candidate}
            candidateModule
            setFilteredCandidates={setFilteredCandidates}
          />
        </td>
      </tr>
    ))}
   
   </>
  )

}