import React from 'react'
import { IoIosSearch } from "react-icons/io";
import { useUserContext } from "../../../context/userContext";
import PageLoader from "../../../components/Utility/PageLoader";
import ViewApplications from "../../../components/Employer/DashboardData/ViewApplications";
import { fetchJobs } from "../../../api/employer/axios";
import { useState, useEffect } from "react";
import {useNavigate} from 'react-router-dom'

const JobListing = () => {
    const [sortType, setSortType] = useState("newest");
    const [isLoading, setIsLoading] = useState(true);
    const [isViewApplicationOn, setIsViewApplicationOn] = useState(false);
    const [selectedJob, setSelectedJob] = useState(null);
    const { user } = useUserContext() 
    const navigate = useNavigate()

    const [myJobs, setMyJobs] = useState();
    const [searchTerm, setSearchTerm] = useState("");
   
   
  
  
  
  
  
    const sortJobs = (jobs, sortType) => {
      switch (sortType) {
        case "newest":
          return jobs.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
        case "oldest":
          return jobs.sort(
            (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
          );
        default:
          return jobs;
      }
    };
  
    const filteredJobs = myJobs && myJobs.filter((job) => {
      const searchTermLower = searchTerm.toLowerCase();
      return (
        job.jobTitle.toLowerCase().includes(searchTermLower) ||
        (searchTermLower.startsWith("ons") && !job.isRemote) ||
        (searchTermLower.startsWith("rem") && job.isRemote)
      );
    });


    useEffect(() => {
        const fetchData = async () => {
          setIsLoading(true)
          if (user?.postedJobs) {
            try {
              const res = await fetchJobs(user?._id);
              if (res?.data?.success) {
                let jobs = res?.data?.allJobs;
                jobs = sortJobs(jobs, sortType);
                setMyJobs(jobs);
              }
            } catch (error) {
              console.error("Error fetching jobs:", error);
            } finally {
              setIsLoading(false);
            }
          }
        };
    
        fetchData();
      }, [user?._id, sortType]);

    const dateFormatter = (str) => {
        const date = new Date(str);
        return date.toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
      };
    
      const handlePauseJob = (jobId) => {
        console.log(`Pausing job with ID: ${jobId}`);
        // Integrate API call to pause the job
      };
    
      const handleCloseJob = (jobId) => {
        console.log(`Closing job with ID: ${jobId}`);
        // Integrate API call to close the job
      };
    
      const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
      };
    
      const handleSortChange = (event) => {
        setSortType(event.target.value);
      };


  return (
    <div className='md:mt-14'>
      <h1 className="text-lg text-[#202124] lg:text-3xl mb-10 font-medium">
        Manage Jobs
      </h1>
      <div className="w-full relative bg-white rounded-lg shadow-lg p-7 pb-14">
        {isLoading && <PageLoader />}
        {isViewApplicationOn && (
          <ViewApplications
            idArray={selectedJob?.applications?.map((app) => app.candidate)}
            setIsViewApplicationOn={setIsViewApplicationOn}
            jobId={selectedJob?._id}
            myjobs
          />
        )}
        <div className="flex flex-col lg:flex-row gap-3 lg:justify-between">
          <div className="bg-[#f0f5f7] rounded-lg p-4 flex items-center gap-2">
            <IoIosSearch color="gray" size={20} />
            <input
              className="bg-[#f0f5f7] rounded-lg p-2 focus:outline-none"
              placeholder="Search ..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
          <div>
            <label className="mr-2">Sort by:</label>
            <select
              className="bg-[#f0f5f7] rounded-lg p-3 px-4 text-start"
              value={sortType}
              onChange={handleSortChange}
            >
              <option value="newest">Newest</option>
              <option value="oldest">Oldest</option>
            </select>
          </div>
        </div>
        <div className="w-full mt-5 overflow-x-auto">
          {filteredJobs && filteredJobs.length ? (
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                  <th className="py-3 px-6 text-center">S.No.</th>
                  <th className="py-3 px-6 text-center">Job Title</th>
                  <th className="py-3 px-6 text-center">Applications</th>
                  <th className="py-3 px-6 text-center">Location</th>
                  <th className="py-3 px-6 text-center">Posted Date</th>
                  <th className="py-3 px-6 text-center">Salary</th>
                  <th className="py-3 px-6 text-center">Qualifications</th>
                  <th className="py-3 px-6 text-center">Total Applications</th>
                  <th className="py-3 px-6 text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredJobs.map((job, i) => (
                  <tr
                    key={job._id}
                    className="bg-white border-b cursor-pointer text-center"
                  >
                    <td className="py-4 px-6">{i + 1}</td>
                    <td className="py-4 px-6">
                      <div
                        onClick={()=>navigate(`/employer/dashboard/myjobs/${encodeURIComponent(
                          job.jobTitle.replace(/\s+/g, "-").toLowerCase()
                        )}/${job._id}`)} 
                        // to={`/jobs/${encodeURIComponent(
                        //   job.jobTitle.replace(/\s+/g, "-").toLowerCase()
                        // )}/${job._id}`}
                        className="text-blue-500 hover:underline"
                      >
                        {job.jobTitle}
                      </div>
                    </td>
                    <td
                      className="py-4 px-6 text-blue-600 underline hover:text-blue-500"
                      onClick={() => {
                        setSelectedJob(job);
                        setIsViewApplicationOn(true);
                      }}
                    >
                      {job?.applications?.length || 0}
                    </td>
                    <td className="py-4 px-6">
                      {job.isRemote ? "Remote" : "Onsite"}
                    </td>
                    <td className="py-4 px-6">{dateFormatter(job.createdAt)}</td>
                    <td className="py-4 px-6">
                      &#8377;{job.minSalary} - &#8377;{job.maxSalary} / month
                    </td>
                    <td className="py-4 px-6">{job.qualificationRequired}</td>
                    <td className="py-4 px-6">{job.applications.length}</td>
                    <td className="py-4 px-6 flex gap-2 justify-center">
                      <button
                        className="bg-yellow-400 hover:bg-yellow-500 text-white py-1 px-3 rounded-lg"
                        onClick={() => handlePauseJob(job._id)}
                      >
                        Pause
                      </button>
                      <button
                        className="bg-red-400 hover:bg-red-500 text-white py-1 px-3 rounded-lg"
                        onClick={() => handleCloseJob(job._id)}
                      >
                        Close
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="text-center text-gray-500 py-4">No jobs found.</p>
          )}
        </div>
      </div>
    </div>
    
  )
}

export default JobListing