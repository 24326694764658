import React from 'react';
import Nav from '../../components/Nav/Nav';
import { motion } from "framer-motion";
import{fadeIn} from "../../Variants";
import ourMission from '../../assets/ourMission.png';
import vision from '../../assets/vision.jpg';
import Footer from './Footer';
  
const AboutUs = () => {
  return (
    <div>
    <Nav bgColor="bg-white fixed shadow-md"/>
    <div className="bg-gray-100 min-h-screen p-8 ">
      {/* Header */} 
      <header className="text-center mb-12 pt-36">
        <h1 className="text-4xl font-bold text-gray-800">About Us</h1>
        <p className="mt-4 text-lg text-gray-600 px-16">
          
          ProJobs is a leading online job portal that connects talented job seekers with top employers across a wide range of industries. Since our launch in 2018, we have become a trusted destination for both job seekers and employers, helping to facilitate thousands of successful hires.

        </p>
      </header>

      {/* Mission Section */}
      
      <section className="mb-12">
        <div className="container mx-auto flex flex-col md:flex-row items-center justify-center">
       
          <motion.div
        variants={fadeIn("right",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once:false,amount:0.7}}
         className="w-full md:w-1/2 p-6">
         
            <img
              src={ourMission}
              className="rounded-lg shadow-md"
              alt= "Our Mission"
            />
          </motion.div>
          <motion.div 
       variants={fadeIn("left",0.2)}
       initial="hidden"
       whileInView={"show"}
       viewport={{once:false,amount:0.7}}
        className="w-full md:w-1/2 p-6">
            <h2 className="text-2xl font-semibold text-gray-800">"Our Mission"</h2>
            <p className="mt-4 text-gray-600 ">" Our mission at ProJobs is to revolutionize the job search and hiring process by leveraging cutting-edge technology and data-driven insights. We strive to empower job seekers with the tools and resources they need to find their dream jobs, while also providing employers with intelligent solutions to identify and attract the best talent. "
            </p>
          </motion.div>
        </div>
      </section>

      {/* Vision Section */}
      <section className="mb-12">
        <div className="container mx-auto flex flex-col-reverse md:flex-row items-center justify-center">
        <motion.div
        variants={fadeIn("right",0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once:false,amount:0.7}} className="w-full md:w-1/2 p-6">
            <h2 className="text-2xl font-semibold text-gray-800">Our Vision</h2>
            <p className="mt-4 text-gray-600">
              We envision a world where everyone has equal access to job
              opportunities, regardless of their background or location.
            </p>
          </motion.div>
          <motion.div 
       variants={fadeIn("left",0.2)}
       initial="hidden"
       whileInView={"show"}
       viewport={{once:false,amount:0.7}} 
       className="w-full md:w-1/2 p-6">
            <img
              src={vision}
              alt="Our Vision"
              className="rounded-lg shadow-md"
            />
          </motion.div>
        </div>
      </section>
      
      
       
      {/* Values Section */}
      <section className="mb-12">
        <div className="container mx-auto">
          <h2 className="text-2xl font-semibold text-gray-800 text-center mb-8">
            Our Core Values
          </h2>
          <div className="flex flex-wrap justify-center">
          <div className="w-full md:w-1/3 p-4">
              <div className="bg-white rounded-lg shadow-lg p-6">
                <h3 className="text-xl font-semibold text-gray-800">
                Fairness
                </h3>
                <p className="mt-2 text-gray-600">
                 We believe in promoting equal opportunities and fostering a diverse, inclusive job market. Our platform is designed to be accessible and transparent for all users.

                </p>
              </div>
            </div>
            <div className="w-full md:w-1/3 p-4">
              <div className="bg-white rounded-lg shadow-lg p-6">
                <h3 className="text-xl font-semibold text-gray-800">
                Efficiency
                </h3>
                <p className="mt-2 text-gray-600">
               We are committed to streamlining the job search and hiring workflows through intelligent automation and seamless user experiences.

                </p>
              </div>
            </div>

            <div className="w-full md:w-1/3 p-4">
              <div className="bg-white rounded-lg shadow-lg p-6">
                <h3 className="text-xl font-semibold text-gray-800">
                  Integrity
                </h3>
                <p className="mt-2 text-gray-600">
                Trust is the foundation of our relationship with both job seekers and employers. We uphold the highest standards of ethics and data privacy in all our operations.

                </p>
              </div>
            </div>
            <div className="w-full md:w-1/3 p-4">
              <div className="bg-white rounded-lg shadow-lg p-6">
                <h3 className="text-xl font-semibold text-gray-800">
                  Innovation
                </h3>
                <p className="mt-2 text-gray-600">
                Constant innovation is at the core of our DNA. We continuously invest in research and development to introduce new features and functionalities that enhance the overall experience for our users.

                </p>
              </div>
            </div>
            <div className="w-full md:w-1/3 p-4">
              <div className="bg-white rounded-lg shadow-lg p-6">
                <h3 className="text-xl font-semibold text-gray-800">Empathy</h3>
                <p className="mt-2 text-gray-600">
                  We understand the challenges our users face and work
                  tirelessly to provide solutions that make a real difference.
                </p>
              </div>
            </div>
            <div className="w-full md:w-1/3 p-4">
              <div className="bg-white rounded-lg shadow-lg p-6">
                <h3 className="text-xl font-semibold text-gray-800">
                  Collaboration
                </h3>
                <p className="mt-2 text-gray-600">
                  We believe in the power of teamwork and partnership. By working together, we can achieve more and make a greater impact.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="text-center mb-12 pt-28">
        <h1 className="text-4xl font-bold text-gray-800">Our Team</h1>
        <p className="mt-4 text-lg text-gray-600 px-16 pb-11">
          
        ProJobs is powered by a dedicated team of professionals with diverse backgrounds in technology, recruiting, and customer service. Our talented individuals work collaboratively to drive the company's vision and deliver exceptional value to our users.

        </p>
      </section>
    </div>
    <Footer/>
    </div>
  );
};

export default AboutUs;