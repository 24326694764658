import React, { useEffect, useState } from "react";
// import { useAdminContext } from "../../context/adminContext";
// import { approveEmployer } from "../../api/admin/axios";
import EmployerDetails from "../../components/Admin/EmployerDetails";
import Loader from "../../components/Utility/Loader";
import AdminOptions from "../../components/Admin/AdminOptions";
import { EmployerFilterBar } from "./FilterBar";
import ImportExport from "../../components/Admin/ImportExport";
import { useNavigate } from "react-router-dom";

const sampleEmployers = [
  {
    _id: "1",
    companyName: "TechCorp",
    email: "techcorp@example.com",
    phoneNumber: "123-456-7890",
   location: { city : "Las Vegas" },
    postJobCredits: 5,
    purchasedPackages: ["Basic", "Premium"],
    postedJobs: ["Job1", "Job2"],
    isApproved: false,
    Package : 5
  },
  {
    _id: "2",
    companyName: "Innovate LLC",
    email: "innovate@example.com",
    phoneNumber: "987-654-3210",
    location: { city: "New york" },
    postJobCredits: 3,
    purchasedPackages: [],
    postedJobs: ["Job1"],
    isApproved: true,
    Package : 10
  },
];

export default function Employers() {
  // const { setAllEmployers, adminToken } = useAdminContext();
  // const [employers, setEmployers] = useState(sampleEmployers);
  const [isEmployerDetailsOn, setIsEmployerDetailsOn] = useState(false);
  const [selectedEmployer, setSelectedEmployer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredEmployers, setFilteredEmployers] = useState(sampleEmployers);

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const navigate = useNavigate();

  const handleRowCheckboxChange = (employerId) => {
    if (selectedRows.includes(employerId)) {
      setSelectedRows(selectedRows.filter((id) => id !== employerId));
    } else {
      setSelectedRows([...selectedRows, employerId]);
    }
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      setSelectedRows(filteredEmployers.map((employer) => employer._id));
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    if (selectedRows.length === filteredEmployers.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedRows, filteredEmployers]);

  const handleFilter = ({ searchTerm, location, Package }) => {
    let filteredData = sampleEmployers;

    if (searchTerm) {                                   // searchFrom name, email and phoneNumber only
      filteredData = filteredData.filter((employer) => ["companyName", "email", "phoneNumber"].some((key) =>
          String(employer[key]).toLowerCase().includes(searchTerm.toLowerCase())
        
      ));
    }
    if (location) {
      filteredData = filteredData.filter((employer) => employer.location.city === location);
    }
    if (Package) {
      filteredData = filteredData.filter((employer) => employer.Package == Package);
    }
   

    setFilteredEmployers(filteredData);
  };

  // const approveEmployerClickHandler = async (employerId) => {
  //   setIsLoading(true);
  //   try {
  //     const res = await approveEmployer(employerId, adminToken);
  //     if (res?.data?.success) {
  //       const updatedEmployers = res?.data?.allEmployers;
  //       setEmployers(updatedEmployers);
  //       setAllEmployers(updatedEmployers);
  //     }
  //   } catch (error) {
  //     console.error("Error approving employer:", error);
  //   }
  //   setIsLoading(false);
  // };

  return (
    <div className="relative w-screen md:w-full overflow-hidden h-full bg-[#f5f7fc]">
      <div className="w-full text-white bg-[#394e86] md:p-5 flex justify-between">
        <p className="text-lg cursor-pointer ms-12">Employer</p>
        <ImportExport 
          filteredData={filteredEmployers}
          setFilteredData={setFilteredEmployers}
          name = {"Employers"}
        />
      </div>

      {isLoading && <Loader />}
      <EmployerFilterBar 
        data={sampleEmployers} 
        onFilter={handleFilter} 
      />

      <div className="w-full h-full overflow-x-auto p-1">
        <table className="w-full divide-gray-200 overflow-x-auto">
          <thead className="bg-gray-800 text-center">
            <tr>
             <th className="px-6 py-3 text-xs font-medium text-white uppercase tracking-wider">
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                  className="w-4 h-4 text-blue-600 cursor-pointer rounded border-gray-300 focus:ring-blue-500"
                />
              </th>

              <th className="px-4 py-3 text-xs font-medium text-white uppercase tracking-wider">
                S.no.
              </th>
              <th className="px-4 py-3 text-xs font-medium text-white uppercase tracking-wider">
                Company Name
              </th>
              <th className="px-4 py-3 text-xs font-medium text-white uppercase tracking-wider">
                Email
              </th>
              <th className="px-4 py-3 text-xs font-medium text-white uppercase tracking-wider">
                Phone Number
              </th>
              <th className="px-4 py-3 text-xs font-medium text-white uppercase tracking-wider">
                Location
              </th>
              <th className="px-4 py-3 text-xs font-medium text-white uppercase tracking-wider">
                Packages
              </th>
              <th className="px-4 py-3 text-xs font-medium text-white uppercase tracking-wider">
                Jobs
              </th>
              <th className="px-4 py-3 text-xs font-medium text-white uppercase tracking-wider">
                Status
              </th>
              <th className="px-4 py-3 text-xs font-medium text-white uppercase tracking-wider">
                Candidates
              </th>
              
              <th className="px-4 py-3 text-xs font-medium text-white uppercase tracking-wider">
                options
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredEmployers.map((employer, index) => (
              <tr key={employer._id} className="hover:bg-gray-50">
                
                <td className="px-1 py-4 text-center text-sm text-gray-900">
                  <input
                    type="checkbox"
                    checked={selectedRows.includes(employer._id)}
                    onChange={() => handleRowCheckboxChange(employer._id)}
                    className="w-4 h-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                  />
                </td>

                <td className="px-3 py-4 text-center whitespace-nowrap text-xs font-medium text-gray-900">
                  {index + 1}.
                </td>
                <td onClick={()=>navigate('/employer-profile')} className="px-3 cursor-pointer py-4 text-center whitespace-nowrap text-xs font-medium text-gray-900">
                  {employer.companyName}
                </td>
                <td className="px-3 py-4 text-center whitespace-nowrap text-xs text-gray-500">
                  {employer.email}
                </td>
                <td className="px-3 py-4 text-center whitespace-nowrap text-xs text-gray-500">
                  {employer.phoneNumber}
                </td>
                <td className="px-3 py-4 text-center whitespace-nowrap text-xs text-gray-500">
                  {employer.location?.city}
                </td>
                <td className="px-3 py-4 text-center whitespace-nowrap text-xs text-gray-500">
                  {employer.Package || "None"}
                </td>
                <td onClick={()=>navigate(`/admin/jobs`)} className="hover:text-[#394e86]  hover:font-bold transition-all duration-200 px-3 py-4 cursor-pointer text-center whitespace-nowrap text-xs text-gray-500">
                  see jobs
                </td>

                <td className="px-3 py-4  hover:text-[#394e86]  hover:bold hover:font-bold transition-all duration-200 text-center whitespace-nowrap text-xs text-gray-500">
                  Active
                </td>
               
                <td onClick={()=>navigate('/candidates')} className="px-3 hover:text-[#394e86] hover:font-bold transition-all duration-200 hover:bold cursor-pointer py-4 text-center whitespace-nowrap text-xs text-gray-500">
                  see candidate
                </td>

                <td className="px-1 py-4 text-center text-sm text-gray-500">
                <AdminOptions
                  data={employer}
                  employerModule
                  setFilteredEmployers={setFilteredEmployers}
                  setIsEmployerDetailsOn={setIsEmployerDetailsOn}
                />
              </td>
              </tr>
            ))}
          </tbody>
        </table>

        {isEmployerDetailsOn && selectedEmployer &&  (
          <EmployerDetails
            isEditing={false}
            setIsEditing={() => {}}
            data={selectedEmployer}
            setIsEmployerDetailsOn={setIsEmployerDetailsOn}
          />
        )}
      </div>
    </div>
  );
}