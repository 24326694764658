import React from "react";
import Card from "../../components/Admin/Card";
import { BsPerson } from "react-icons/bs";
import { BiShoppingBag } from "react-icons/bi";
import { GrCertificate } from "react-icons/gr";
import { RiAdminFill } from "react-icons/ri";
import { useAdminContext } from "../../context/adminContext";

export default function Dashboard() {
  const { adminData, allAdmins, jobs } = useAdminContext();
  return (
    <div className="w-full flex flex-col h-screen ">
      <div className="w-full text-white bg-[#394e86] shadow-2xl md:p-5 flex justify-between">
        <p className="text-lg font-semibold ms-12 ">Dashboard</p>
        <p>Hi,{adminData?.name?.split(" ")[0]}!</p>
      </div>
      <div className="overflow-auto flex flex-wrap justify-evenly bg-[#f5f7fc] gap-2 p-6">
        <Card
          title="Candidates"
          count={9} //allCandidates?.length
          icon={<BsPerson size={70}
          //  color="green" 
           className="text-[#6ad61d] "/>}
        />
        <Card
          title="Employers"
          count={10} //allEmployers?.length
          icon={<BiShoppingBag size={70} 
          // color="green" 
          className="text-[#6ad61d] "/>}
        />
        <Card
          title="Jobs"
          count={jobs?.length}
          icon={<GrCertificate size={60} 
           // color="green" 
           className="text-[#6ad61d] "/>}
        />
        {adminData?.isSuperAdmin && (
          <Card
            title="Admins"
            count={allAdmins?.length - 1}
            icon={<RiAdminFill size={70} 
             // color="green" 
          className="text-[#6ad61d] "/>}
          />
        )}
        <div className=" w-full mt-6 pb-5 lg:px-16">
          <div className=" w-full h-96 bg-white rounded-lg shadow-lg p-7">
            <p className="text-lg font-medium">Recent Activities</p>
          </div>
        </div>
      </div>
    </div>
  );
}