import React, { createContext, useContext, useEffect, useState } from "react";
import { fetchAllAdmins, fetchPostedJobs } from "../api/admin/axios";

const adminContext = createContext();

export default function AdminContextProvider({ children }) {
  const [adminData, setAdminData] = useState("");
  const [adminToken, setAdminToken] = useState("");
  const [jobs, setJobs] = useState([]);
  const [allAdmins, setAllAdmins] = useState([]);
  // const [allHRS, setAllHRS] = useState([]);
  const [allEmployers, setAllEmployers] = useState([]);
  const [allCandidates, setAllCandidates] = useState([]);
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    // Fetch token from sessionStorage
    const token = sessionStorage.getItem("adminToken");
    if (token) {
      setAdminToken(token);
      const data = sessionStorage.getItem("adminData");
      setAdminData(JSON.parse(data));
    }

    // Fetch all admins
    const fetchAllAdmin = async () => {
      try {
        const response = await fetchAllAdmins(token); // Assuming fetchAllAdmins is a function to fetch all admins
        if (response?.data?.admin?.isSuperAdmin) {
          setAllAdmins(response?.data?.allAdmins);
          setPackages(response?.data?.allPackages);
        }
        setAdminData(response?.data?.admin);
        // setAllHRS(response?.data?.allHRS);
        setAllEmployers(response?.data?.allEmployers);
        setAllCandidates(response?.data?.allCandidates);
        console.log(response);
      } catch (error) {
        console.error("Error fetching all admins:", error);
      }
    };
    //  const fetchAllHR= async () => {
    //   try {
    //     const response = await fetchAllHRS(token); // Assuming fetchAllhr is a function to fetch all hr
    //     if (response?.data?.hr) {
    //       setAllAdmins(response?.data?.allHRS);
    //       setPackages(response?.data?.allPackages);
    //     }
    //     setAdminData(response?.data?.admin);
    //     // setAllHRS(response?.data?.allHRS);
    //     setAllEmployers(response?.data?.allEmployers);
    //     setAllCandidates(response?.data?.allCandidates);
    //     console.log(response);
    //   } catch (error) {
    //     console.error("Error fetching all hrs:", error);
    //   }
    // };


    const fetchJobs = async () => {
      if (adminToken) {
        const res = await fetchPostedJobs(adminToken);
        if (res && res.data && res.data.allJobs) {
          setJobs(res.data.allJobs);
        }
      }
    };
    fetchJobs();

    fetchAllAdmin();
    // fetchAllHR();
  }, [adminToken,  setAllEmployers, setAllCandidates]);

  return (
    <adminContext.Provider
      value={{
        adminData,
        setAdminData,
        adminToken,
        setAdminToken,
        jobs,
        setJobs,
        allAdmins,
        setAllAdmins,
        allEmployers,
        // allHRS,
        // setAllHRS,
        setAllEmployers,
        allCandidates,
        setAllCandidates,
        packages,
        setPackages,
      }}
    >
      {children}
    </adminContext.Provider>
  );
}

export const useAdminContext = () => {
  return useContext(adminContext);
};
