
import {Outlet} from "react-router-dom";



function MyJobs() {


  return (
    <div className="w-full h-auto overflow-y-auto  px-4 lg:px-14  pb-14">
        <Outlet />
    </div>
  );
}

export default MyJobs;